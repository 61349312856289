import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import PortableText from '@sanity/block-content-to-react';
import SEO from '../components/SEO';
import Breadcrumbs from '../components/Breadcrumbs';
import { PageGrid } from '../styles/PageStyles';
import RequestACallback from '../components/RequestACallback';

export default function ServicePage({ data }) {
  const { service, site } = data;
  return (
    <>
      <SEO
        title={service.title}
        image={service.image?.asset?.fluid?.src}
        description={`Call Emerald Heating now on 07538008378 to discuss your ${service.title} needs.`}
      />
      <Breadcrumbs currentPage={service} parent={site.service} />
      {service.image?.asset?.fluid && (
        <Img fluid={service.image?.asset?.fluid} alt={service.title} />
      )}

      <PageGrid>
        <div>
          <h1>{service.title}</h1>
          <p>{service.description}</p>
          {service._rawContent && <PortableText blocks={service._rawContent} />}
        </div>

        <RequestACallback />
      </PageGrid>
    </>
  );
}

export const query = graphql`
  query($slug: String!) {
    service: sanityService(slug: { current: { eq: $slug } }) {
      title
      id
      slug {
        current
      }
      description
      _rawContent(resolveReferences: { maxDepth: 10 })
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    site: sanitySiteSettings {
      service {
        title
        slug {
          current
        }
      }
    }
  }
`;
